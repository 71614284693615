<template>
  <b-card body-class="p-0" header-class="border-0">

    <b-row>
      <b-col xl="2" md="6">
        <b-card no-body class="bg-gradient-primary border-0">
          <!-- Card body -->
          <b-card-body>
            <b-row>
              <b-col>
                <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Fare Collected</b-card-title>
                <span class="h2 font-weight-bold mb-0 text-white">{{this.summary['fare']}}</span>
                <!--                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="30"></base-progress>-->
              </b-col>
              <b-col md="auto">
                <b-dropdown
                  no-caret right
                  toggle-class="btn btn-sm btn-neutral mr-0"
                >
                  <template v-slot:button-content>
                    <i class="fas fa-ellipsis-h"></i>
                  </template>

                  <b-dropdown-item>Action</b-dropdown-item>
                  <b-dropdown-item>Another action</b-dropdown-item>
                  <b-dropdown-item>Something else here</b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
            <p class="mt-3 mb-0 text-sm">
              <a href="#!" class="text-nowrap text-white font-weight-600">See details</a>
            </p>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="2" md="6">
        <b-card no-body class="bg-gradient-info border-0">
          <!-- Card body -->
          <b-card-body>
            <b-row>
              <b-col>
                <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">DAY PASSES FARE</b-card-title>
                <span class="h2 font-weight-bold mb-0 text-white">{{this.summary['fare-package']}}</span>
                <!--                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="50"></base-progress>-->
              </b-col>
              <b-col md="auto">
                <b-dropdown
                  no-caret right
                  toggle-class="btn btn-sm btn-neutral mr-0"
                >
                  <template v-slot:button-content>
                    <i class="fas fa-ellipsis-h"></i>
                  </template>

                  <b-dropdown-item>Action</b-dropdown-item>
                  <b-dropdown-item>Another action</b-dropdown-item>
                  <b-dropdown-item>Something else here</b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
            <p class="mt-3 mb-0 text-sm">
              <a href="#!" class="text-nowrap text-white font-weight-600">See details</a>
            </p>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="2" md="6">
        <b-card no-body class="bg-gradient-danger border-0">
          <!-- Card body -->
          <b-card-body>
            <b-row>
              <b-col>
                <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">TOTAL CASH IN (PAO)</b-card-title>
                <span class="h2 font-weight-bold mb-0 text-white">{{this.summary['cash-in']}}</span>
                <!--                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="80"></base-progress>-->
              </b-col>
              <b-col md="auto">
                <b-dropdown
                  no-caret right
                  toggle-class="btn btn-sm btn-neutral mr-0"
                >
                  <template v-slot:button-content>
                    <i class="fas fa-ellipsis-h"></i>
                  </template>

                  <b-dropdown-item>Action</b-dropdown-item>
                  <b-dropdown-item>Another action</b-dropdown-item>
                  <b-dropdown-item>Something else here</b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
            <p class="mt-3 mb-0 text-sm">
              <a href="#!" class="text-nowrap text-white font-weight-600">See details</a>
            </p>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="2" md="6">
        <b-card no-body class="bg-gradient-default border-0">
          <!-- Card body -->
          <b-card-body>
            <b-row>
              <b-col>
                <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">TOTAL COUNT FARE</b-card-title>
                <span class="h2 font-weight-bold mb-0 text-white">{{this.summary['fare-count']}}</span>
                <!--                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="90"></base-progress>-->
              </b-col>
              <b-col md="auto">
                <b-dropdown
                  no-caret right
                  toggle-class="btn btn-sm btn-neutral mr-0"
                >
                  <template v-slot:button-content>
                    <i class="fas fa-ellipsis-h"></i>
                  </template>

                  <b-dropdown-item>Action</b-dropdown-item>
                  <b-dropdown-item>Another action</b-dropdown-item>
                  <b-dropdown-item>Something else here</b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
            <p class="mt-3 mb-0 text-sm">
              <a href="#!" class="text-nowrap text-white font-weight-600">See details</a>
            </p>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="2" md="6">
        <b-card no-body class="bg-gradient-default border-0">
          <!-- Card body -->
          <b-card-body>
            <b-row>
              <b-col>
                <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">QRPH TRANSACTION</b-card-title>
                <span class="h2 font-weight-bold mb-0 text-white">0</span>
                <!--                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="90"></base-progress>-->
              </b-col>
              <b-col md="auto">
                <b-dropdown
                  no-caret right
                  toggle-class="btn btn-sm btn-neutral mr-0"
                >
                  <template v-slot:button-content>
                    <i class="fas fa-ellipsis-h"></i>
                  </template>

                  <b-dropdown-item>Action</b-dropdown-item>
                  <b-dropdown-item>Another action</b-dropdown-item>
                  <b-dropdown-item>Something else here</b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
            <p class="mt-3 mb-0 text-sm">
              <a href="#!" class="text-nowrap text-white font-weight-600">See details</a>
            </p>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="2" md="6">
        <b-card no-body class="bg-gradient-default border-0">
          <!-- Card body -->
          <b-card-body>
            <b-row>
              <b-col>
                <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">CASH IN (PISOPAY)</b-card-title>
                <span class="h2 font-weight-bold mb-0 text-white">0</span>
                <!--                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="90"></base-progress>-->
              </b-col>
              <b-col md="auto">
                <b-dropdown
                  no-caret right
                  toggle-class="btn btn-sm btn-neutral mr-0"
                >
                  <template v-slot:button-content>
                    <i class="fas fa-ellipsis-h"></i>
                  </template>

                  <b-dropdown-item>Action</b-dropdown-item>
                  <b-dropdown-item>Another action</b-dropdown-item>
                  <b-dropdown-item>Something else here</b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
            <p class="mt-3 mb-0 text-sm">
              <a href="#!" class="text-nowrap text-white font-weight-600">See details</a>
            </p>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <template v-slot:header>
      <h3 class="mb-0">Bus</h3>
    </template>

    <el-table class="table-responsive table-flush"
              header-row-class-name="thead-light"
              :data="projects.slice(0, itemLimit)">
      <el-table-column label="Transaction No"
                       min-width="250px"
                       prop="name"
                       sortable>
        <template v-slot="{row}">
          <b-media no-body class="align-items-center">
            <b-media-body>
              <span class="font-weight-600 name mb-0 text-sm">{{row.transaction_no}}</span>
            </b-media-body>
          </b-media>
        </template>
      </el-table-column>

      <el-table-column label="Transaction Type"
                       prop="transaction_type"
                       min-width="200px"
                       sortable>
      </el-table-column>

      <el-table-column label="Amount"
                       prop="amount"
                       min-width="200px"
                       sortable>
      </el-table-column>

      <el-table-column label="Discount(Amount)"
                       prop="discount"
                       min-width="200px"
                       sortable>
      </el-table-column>

      <el-table-column label="Fee(Amount)"
                       prop="fee"
                       min-width="200px"
                       sortable>
      </el-table-column>

      <el-table-column label="Transaction Time"
                       prop="formattedTime"
                       min-width="200px"
                       sortable>
      </el-table-column>

      <el-table-column label="Transaction Date"
                       prop="formattedDate"
                       min-width="200px"
                       sortable>
      </el-table-column>

    </el-table>
  </b-card>
</template>

<script>
import axios from 'axios';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown } from 'element-ui';
export default {
  name: 'transaction-details',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    itemLimit: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      summary: [],
      projects: [],
      currentPage: 1
    };
  },
  methods: {
    loadProject() {
      const id = this.$route.params.id; // getting

      axios.get(`https://hoho-api.pisopay.com.ph/api/operation-transactions/${id}`)
        .then(response => {
          this.summary = response.data.summary;
          this.projects = response.data.transactions.map(transaction => {
            const date = new Date(transaction.created_at);
            transaction.formattedDate = `${date.getFullYear()}-${("0" + (date.getMonth()+1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
            transaction.formattedTime = `${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`;
            return transaction;
          });
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.loadProject();
  }
};
</script>
