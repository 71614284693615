<template>
  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <h3 class="mb-0">Bus Trip</h3>
    </template>

    <el-table class="table-responsive table-flush"
              header-row-class-name="thead-light"
              :data="projects.slice(0, itemLimit)"
              @row-click="goToDetailsPage"
              :highlight-current-row="true">
      <el-table-column label="Bus Number"
                       min-width="200px"
                       prop="name"
                       sortable>
        <template v-slot="{row}">
          <b-media no-body class="align-items-center">
            <a href="#" class="avatar rounded-circle mr-3">
              <b-img alt="Image placeholder" :src="row.img"></b-img>
            </a>
            <b-media-body>
              <span class="font-weight-600 name mb-0 text-sm">{{row.bus}}</span>
            </b-media-body>
          </b-media>
        </template>
      </el-table-column>

      <el-table-column label="Trip Number"
                       prop="operation_id"
                       min-width="200px"
                       sortable>
      </el-table-column>

      <el-table-column label="Fare Collection(Amount)"
                       prop="fare"
                       min-width="200px"
                       sortable>
      </el-table-column>

      <el-table-column label="Cash In (Amount)"
                       prop="cash-in"
                       min-width="150px"
                       sortable>
      </el-table-column>

      <el-table-column v-slot="{row}" label="PAO" min-width="190px">
        <div class="avatar-group">
            <a href="#">{{row.operator}}</a>
<!--          <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"-->
<!--             data-original-title="Ryan Tompson">-->
<!--            <img alt="Image placeholder" src="img/theme/team-1.jpg">-->
<!--          </a>-->
        </div>
      </el-table-column>

      <el-table-column v-slot="{row}" label="Driver" min-width="190px">
        <div class="avatar-group">
          <a href="#">{{row.driver}}</a>
<!--          <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"-->
<!--             data-original-title="Romina Hadid">-->
<!--            <img alt="Image placeholder" src="img/theme/team-2.jpg">-->
<!--          </a>-->
        </div>
      </el-table-column>


      <el-table-column label="DATE AND TIMNE"
                       prop="created-at"
                       min-width="150px"
                       sortable>
      </el-table-column>





    </el-table>
  </b-card>
</template>

<script>
import axios from 'axios';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown } from 'element-ui';
export default {
  name: 'transaction-table',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    itemLimit: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      projects: [],
      currentPage: 1
    };
  },
  methods: {
    loadProject() {
      axios.get('https://hoho-api.pisopay.com.ph/api/operationDetails')
        .then(response => {
          this.projects = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },

    goToDetailsPage(row) {
      this.$router.push(`/transaction/details/${row.operation_id}`);
    }
  },
  mounted() {
    this.loadProject();
  }
};
</script>

<style>
div.el-table .el-table__row:hover{
  cursor: pointer;
  background: #ddffdc;
}
</style>
