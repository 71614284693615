<template>
  <div >
    <base-header class="pb-6" type="">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 d-inline-block mb-0">Fare Management</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <b-breadcrumb class="breadcrumb-links">
              <b-breadcrumb-item><router-link to="/"><i class="fas fa-home"></i></router-link></b-breadcrumb-item>
              <b-breadcrumb-item><a href="#" aria-current="page">Fare Management</a></b-breadcrumb-item>
            </b-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
      </b-row>
    </base-header>


    <b-container fluid class="mt--6">
      <b-card-group>
        <b-card
          title="Regular Fare"
          tag="article"
          style="max-width: 20rem;"
          class="mb-2"
        >
          <b-card-text>
            This is for editing managing the Fare Prices.
          </b-card-text>

          <b-button href="javascript:;" variant="primary">Edit Regular Fare</b-button>
        </b-card>
      </b-card-group>

      <b-card
        title="Package Fare"
        tag="article"
        style="max-width: 20rem;"
        class="mb-2"
      >
        <b-card-text>
          This is for managing the Package Prices.
        </b-card-text>

        <b-button href="javascript:;" variant="primary">Edit Package Fare</b-button>
      </b-card>
    </b-container>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';

// Components
import BaseHeader from '@/components/BaseHeader';
import BaseProgress from '@/components/BaseProgress';

// Lists
import MembersCard from '@/views/Widgets/MembersCard';
import TaskList from '@/views/Widgets/TaskList';

// Tables
import TransactionTable from './TransactionTable';
import VectorMapCard from '@/views/Widgets/VectorMapCard'
import UsersTable from "@/views/Transaction/UsersTable.vue";

    export default {
    components: {
    UsersTable,
    LineChart,
    BarChart,
    BaseHeader,
    BaseProgress,
    TaskList,
    VectorMapCard,
    TransactionTable,
    MembersCard
  },
    data() {
    return {
    bigLineChart: {
    chartData: {
    datasets: [
  {
    label: 'Performance',
    data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
  }
    ],
    labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
    extraOptions: chartConfigs.blueChartOptions,
  },
    redBarChart: {
    chartData: {
    labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [{
    label: 'Sales',
    data: [25, 20, 30, 22, 17, 29]
  }]
  }
  }
  };
  }
  };
</script>

<!--<style>-->
<!--//.fare-custom {-->
<!--//  width: 200px;-->
<!--//  height: 300px;-->
<!--//  background: red;-->
<!--//}-->
<!--//-->
<!--//.pass-custom {-->
<!--//  width: 200px;-->
<!--//  height: 300px;-->
<!--//  background: blue;-->
<!--//}-->
<!--</style>-->

