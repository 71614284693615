<template>
  <div>
    <base-header class="pb-6" type="">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 d-inline-block mb-0">Transaction</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <b-breadcrumb class="breadcrumb-links">
              <b-breadcrumb-item><router-link to="/"><i class="fas fa-home"></i></router-link></b-breadcrumb-item>
              <b-breadcrumb-item><a href="#" aria-current="page">Transaction</a></b-breadcrumb-item>
            </b-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
      </b-row>
    </base-header>

    <!--Charts-->
    <b-container fluid class="mt--6">
      <!--Tables & Widgets-->
            <b-row>
              <b-col xl="12">
                <transaction-table :item-limit="10"></transaction-table>
              </b-col>
            </b-row>
      <!--End Tables & Widgets-->
    </b-container>

  </div>
</template>
<script>
// Charts
import * as chartConfigs from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';

// Components
import BaseHeader from '@/components/BaseHeader';
import BaseProgress from '@/components/BaseProgress';

// Lists
import MembersCard from '@/views/Widgets/MembersCard';
import TaskList from '@/views/Widgets/TaskList';

// Tables
import TransactionTable from './TransactionTable';
import VectorMapCard from '@/views/Widgets/VectorMapCard'

export default {
  components: {
    LineChart,
    BarChart,
    BaseHeader,
    BaseProgress,
    TaskList,
    VectorMapCard,
    TransactionTable,
    MembersCard
  },
  data() {
    return {
      bigLineChart: {
        chartData: {
          datasets: [
            {
              label: 'Performance',
              data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
            }
          ],
          labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [{
            label: 'Sales',
            data: [25, 20, 30, 22, 17, 29]
          }]
        }
      }
    };
  }
};
</script>
<style></style>
