<template>
  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <h3 class="mb-0">Users</h3>
    </template>

    <el-table class="table-responsive table-flush"
              header-row-class-name="thead-light"
              :data="projects.slice(0, itemLimit)"
              :highlight-current-row="true">
      <el-table-column label="ID"
                       min-width="200px"
                       prop="name"
                       sortable>
        <template v-slot="{row}">
          <b-media no-body class="align-items-center">
            <b-media-body>
              <span class="font-weight-600 name mb-0 text-sm">{{row.id}}</span>
            </b-media-body>
          </b-media>
        </template>
      </el-table-column>

      <el-table-column label="Username"
                       prop="username"
                       min-width="100px"
                       sortable>
      </el-table-column>

      <el-table-column label="Email"
                       prop="email"
                       min-width="100px"
                       sortable>
      </el-table-column>

      <el-table-column label="First Name"
                       prop="firstname"
                       min-width="100px"
                       sortable>
      </el-table-column>

      <el-table-column label="Last Name"
                       prop="lastname"
                       min-width="100px"
                       sortable>
      </el-table-column>

      <el-table-column label="Nationality"
                       prop="nationality"
                       min-width="100px"
                       sortable>
      </el-table-column>

      <el-table-column label="Balance"
                       prop="wallet.balance"
                       min-width="100px"
                       sortable>
      </el-table-column>



    </el-table>
  </b-card>
</template>

<script>
import axios from 'axios';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown } from 'element-ui';
export default {
  name: 'users-table',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    itemLimit: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      projects: [],
      currentPage: 1
    };
  },
  methods: {
    loadProject() {
      axios.get('https://hoho-api.pisopay.com.ph/api/users?userType=0')
        .then(response => {
          this.projects = response.data.data;
        })
        .catch(error => {
          console.error(error);
        });
    },

    goToDetailsPage(row) {
      this.$router.push(`/transaction/details/${row.operation_id}`);
    }
  },
  mounted() {
    this.loadProject();
  }
};
</script>

<style>
div.el-table .el-table__row:hover{
  cursor: pointer;
  background: #ddffdc;
}
</style>
