<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7" >
          <h6 class="h2 text-white d-inline-block mb-0">{{$route.name}}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <b-card no-body class="border-0">
            <div id="map" class="map-canvas"
                 style="height: 600px;"></div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";
import {API_KEY} from "@/views/Maps/API_KEY";
const loader = new Loader({ apiKey: API_KEY });


  export default {
    mounted() {
      loader.load().then(function () {

        // Regular Map
        const myLatlng = new google.maps.LatLng(11.959713, 121.928519);
        const locations = [
          {lat: 11.940457, lng: 121.9392529},
          {lat: 11.9571099, lng: 121.9306194},
          {lat: 11.9630719, lng: 121.9265641},
          {lat: 11.9654013, lng: 121.9227639},
          {lat: 11.9747087, lng: 121.9184274},
          {lat: 11.9793459, lng: 121.9291082},
        ];

        const icons = [
          {src: "img/icons/bus/H1.png"},
          {src: "img/icons/bus/H2.png"},
          {src: "img/icons/bus/H3.png"},
          {src: "img/icons/bus/H4.png"},
          {src: "img/icons/bus/H5.png"},
          {src: "img/icons/bus/H6.png"},
        ];


        const mapOptions = {
          zoom: 14,
          center: myLatlng,
          scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
          disableDefaultUI: true, // a way to quickly hide all controls
          zoomControl: true,
          styles: [
            {
              featureType: "administrative",
              elementType: "labels.text.fill",
              stylers: [{ color: "#444444" }],
            },
            {
              featureType: "landscape",
              elementType: "all",
              stylers: [{ color: "#f2f2f2" }],
            },
            {
              featureType: "poi",
              elementType: "all",
              stylers: [{ visibility: "on" }],
            },
            {
              featureType: "road",
              elementType: "all",
              stylers: [{ saturation: 0 }, { lightness: 0 }],
            },
            {
              featureType: "road.highway",
              elementType: "all",
              stylers: [{ visibility: "simplified" }],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.icon",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              elementType: "all",
              stylers: [{ visibility: "on" }],
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
            },
          ],
        };
        const map = new google.maps.Map(
          document.getElementById("map"),
          mapOptions
        );



        for(let i = 0; i < locations.length; i++) {
          new google.maps.Marker({
            position: locations[i],
            map: map,
            // If you want to change the icon for each marker, you can add an icon property here like so:
            // icon: "https://cdn3.iconfinder.com/data/icons/travel-and-holidays-1-1/48/54-64.png",
            // icon: "https://maps.google.com/mapfiles/kml/shapes/bus_maps.png",
            // url: 'http://maps.google.com/mapfiles/ms/icons/bus.png',
            // url: 'https://cdn3.iconfinder.com/data/icons/travel-and-holidays-1-1/48/54-64.png', // url to your icon
            icon: {
              url: window.location.origin + '/' + icons[i].src, // url to your icon
              scaledSize: new google.maps.Size(40, 40), // size of the icon
            }
          });
        }

        marker.setMap(map);
      });
    },
  };
</script>
