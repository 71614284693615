<template>
  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <h3 class="mb-0">Bus List</h3>
    </template>

    <el-table class="table-responsive table-flush"
              header-row-class-name="thead-light"
              :data="projects.slice(0, itemLimit)"
              :highlight-current-row="true">

      <el-table-column label="Bus Number"
                       prop="id"
                       min-width="200px"
                       sortable>
      </el-table-column>

      <el-table-column label="Vehicle Number"
                       prop="vehicle_no"
                       min-width="200px"
                       sortable>
      </el-table-column>

      <el-table-column v-slot="{row}" label="Status" min-width="190px">
          <div class="avatar-group">
            {{row.status === 1 ? 'active' : 'inactive'}}
          </div>
      </el-table-column>


    </el-table>
  </b-card>
</template>

<script>
import axios from 'axios';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown } from 'element-ui';
export default {
  name: 'pao-table',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    itemLimit: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      projects: [],
      currentPage: 1
    };
  },
  methods: {
    loadProject() {
      axios.get('https://hoho-api.pisopay.com.ph/api/vehicles')
        .then(response => {
          this.projects = response.data.data;
        })
        .catch(error => {
          console.error(error);
        });
    },

    goToDetailsPage(row) {
      this.$router.push(`/bus/details/${row.id}`);
    }
  },
  mounted() {
    this.loadProject();
  }
};
</script>

<style>
div.el-table .el-table__row:hover{
  cursor: pointer;
  background: #ddffdc;
}
</style>
